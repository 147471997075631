<template>
    <div class="questionsDetailBg">
        <div class="questionsDetailWidth">
          <div class="questionsDetailss">
            <p @click="returnQuestionList()">返回上一页</p>
<!--            <p>试题列表——详情页</p>-->
          </div>
          <div >
            <div >
              <exercises-s-c
                  ref="std"
                  v-if="resourceExercises.exercisesType === 'SC'"
                  :exercisesInfo="resourceExercises"
                  :openType="2"
                  :isShowRightAndAnalysis="true"
              ></exercises-s-c>
              <exercises-m-c
                  ref="std"
                  v-if="resourceExercises.exercisesType === 'MC'"
                  :exercisesInfo="resourceExercises"
                  :openType="2"
                  :isShowRightAndAnalysis="true"
              ></exercises-m-c>
              <!-- 填空题 -->
              <exercises-f-b
                  ref="std"
                  v-if="resourceExercises.exercisesType === 'FB'"
                  :exercisesInfo="resourceExercises"
                  :openType="2"
                  :isShowRightAndAnalysis="true"
              >
              </exercises-f-b>
              <!-- 判断题 -->
              <exercises-t-f
                  ref="std"
                  v-if="resourceExercises.exercisesType=== 'TF'"
                  :exercises-info="resourceExercises"
                  :open-type="2"
                  :isShowRightAndAnalysis="true"
              ></exercises-t-f>
              <!-- 简答题 -->
              <exercises-s-a-q
                  ref="std"
                  v-else-if="resourceExercises.exercisesType === 'SAQ'"
                  :exercisesInfo="resourceExercises"
                  :openType="2"
                  :isShowRightAndAnalysis="true"
              >
              </exercises-s-a-q>
            </div>
          </div>
        </div>
        </div>
</template>
<script>
// import workList from "@/components/workList";
import VueAliplayerV2 from 'vue-aliplayer-v2';
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
export default {
    components: {ExercisesTF, ExercisesSC, ExercisesMC, ExercisesFB, ExercisesSAQ,VueAliplayerV2,},
    data(){
        return{
          resourceExercises:{}
        }
    },
    methods:{
    //  返回上一页
      returnQuestionList(){
        this.$router.push({path:'/classCenter'});
        sessionStorage.setItem('questionListId',"9-1")
      }
    },
    mounted(){
      document.documentElement.scrollTop = 0;
      this.resourceExercises=JSON.parse( sessionStorage.getItem('questionDetail'))
    }
}
</script>
<style scoped>
@import "../../public/css/questionsDetail.css";
@import "../../public/css/courseDetail.css";
@import "../../public/css/modifyPaper.css";
</style>
